/* SLIDER VARS */
$slider_height_sm: 420px;
$slider_height_md: 420px;
$slider_height_lg: 420px;
$slider_height_xl: 540px;
$slider_height: 625px;

/* vidjet sto sa ovim  TODO NA TRELLO */
.main-header {
    color: color('white');
    text-align: center;
    font-family: font(main);

    .image-layer {
        background-size: cover !important;
        height: 1060px;
        z-index: 30;
        position: relative;
        max-width: 100vw;
    }

    .header-title {
        font-weight: 200;
        font-size: 5.174rem;
        padding-top: 40vh;
        display: block;
    }

    .header-subtitle {
        font-weight: 200;
        font-size: 4.5rem;
    }

    .header-text {
        font-weight: 200;
        font-size: 1.357rem;

        p {
            font-weight: 200;
            font-size: 1.357rem;
            margin-bottom: 1rem;
        }
    }

    @media (max-width: $media_md) {
        .header-title {
            padding-top: 30vh;
            font-size: 3.174rem;
        }
    }
}

#homepage {
    .header-slider-wrapper {
        .header-slider-item {
            height: calc(100vh - 149px);
            background-position: top center;

            @media (max-width: $media_md) {
            height: $slider_height_md;
            }

            @media (max-width: $media_sm) {
                height: $slider_height_sm;
            }
        }
    }
}

/* SLIDER STYLES */
.header-slider-wrapper {
    overflow: visible; // hidden for for arrows to be inside

    .header-slider-item {
        // background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: $slider_height;

        @media (max-width: $media_md) {
            height: $slider_height_md;
        }

        @media (max-width: $media_sm) {
            height: $slider_height_sm;
        }

        .container {
            height: inherit;
            display: flex;
            align-items: center;
        }

        img {
            width: 100%;
        }
    }

    /* SLICK NAVIGATION ARROWS */
    .navi {
        position: absolute;
        top: 50%;
        z-index: 1;
        cursor: pointer;
        font-size: 30px;
        background: color('primary');
        line-height: 30px;
        width: 40px;
        height: 40px;
        border-radius: $border_radius;
        margin: -25px 15px 0 15px;

        &.navi-prev {
            left: 1px;
            color: color('white');
        }

        &.navi-next {
            right: 1px;
            color: color('white');
        }

        &.slick-disabled {
            @include opacity(0.2);
            pointer-events: none;
            cursor: default;
        }

        i {
            font-size: 2rem;
        }
    }

    .navi {
        color: #ffffff;
    }

    .slick-dots {
        position: absolute;
        bottom: -70px;
    }

    #video-holder {
        .play-btn-bg {
            // left: 47%;
        }
    }
}

.header-slider-content {
    font-size: 1.8rem;
    font-weight: 400;
    width: 100%;
    color: color('white');
    text-align: center;

    @media(max-width: 992px) {
        margin-top: 50px;
    }

    .header-slider-title {
        font-family: font(main);
        font-weight: 600;
        font-size: 50px;
        line-height: 1.1;
        margin-bottom: 20px;
        max-width: 100%;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);

        @media(max-width: 768px) {
            font-size: 35px;
        }

        strong,
        b,
        em,
        i {
            display: block;
            font-weight: 700;
        }
    }

    .header-slider-separator {
        border: none;
        height: 3px;
        background: color('white');
        color: color('white');
        width: 100px;
        position: relative;

        @media(max-width: 768px) {
            display: none;
        }

        &:before {
            content: '';
            height: 1px;
            color: color('white');
            background: color('white');
            width: 50px;
            display: block;
            position: absolute;
            left: -50px;
            top: 1px;
        }

        &:after {
            content: '';
            height: 1px;
            color: color('white');
            background: color('white');
            width: 50px;
            display: block;
            position: absolute;
            right: -50px;
            top: 1px;
        }
    }

    p {
        margin-bottom: 30px;
        line-height: 24px;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
        font-family: font(sub);
        font-size: 15px;
    }

    .header-slider-text {
        margin: 0 auto 30px auto;
        max-width: 65%;
        text-align: center;

        p {
            margin: 0;
            line-height: 24px;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
            font-family: font(sub);
            font-size: 15px;
        }

        @media(max-width: $media_sm) {
            max-width: unset;
        }
    }

    &.text-right {
        p {
            margin-left: auto;
        }
    }
}

/* YouTube and Image Overlay */
#video-holder {
    position: relative;
    width: 100%;
    height: 100%;

    .img-overlay {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
    }

    .play-btn-bg {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        // margin: -100px 0 0 -26px;
        // left: 45%;
        left: 50%;
        padding: 1%;
        border-radius: 50%;
        background: color('primary');

        .video-play-btn {
            // width: 150px;
            width: 75px;
            // height: 150px;
            height: 75px;
            background: transparent;
            border-radius: 50%;

            i {
                font-size: 38px;
                margin: 0 auto;
                color: color('white');
            }
        }
    }
}

/* SLICK NAVIGATION DOTS */
.slick-dots {
    position: absolute;
    bottom: -70px;
    width: 100%;
    padding: 0;
    text-align: center;
    margin: 0;

    li {
        display: inline-block;
        margin: 0 4px;

        button {
            border: 2px solid color('primary');
            background-color: color('white');
            border-radius: 100%;
            width: 12px;
            height: 12px;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            padding: 0;
            outline: 0;
        }

        &.slick-active {
            button {
                background: color('primary');
            }
        }
    }
}

.slider-buttons {
    a {
        img {
            width: auto !important;
            display: inline-block;
        }

        &:last-child {
            margin-left: 10px;

            @media (max-width: 340px) {
                margin-left: 0;

                img {
                    margin-top: 10px;
                }
            }
        }
    }
}

/* MEDIA QUERIES */
@media (min-width: $media_xxl) {
    .header-slider-wrapper {
        .header-slider-item {
            height: $slider_height;
        }
    }
}

@media (max-width: $media_md) {
    .navi {
        display: none !important;
    }

    #video-holder {
        .play-btn-bg {
            // left: 47%;

            .video-play-btn {
                width: 75px;
                height: 75px;

                i {
                    font-size: 2.5rem;
                }
            }
        }
    }
}

@media (max-width: $media_sm) {
    .header-slider-content {
        .header-slider-title {
            font-size: 30px;
        }
    }
}

@media (max-width: $media_xs) {
    .header-slider-content {
        .header-slider-title {
            font-size: 25px;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.9);
        }

        .header-slider-text {
            p {
                text-shadow: 1px 1px rgba(0, 0, 0, 0.9);
            }
        }
    }
}

/* SCROLL ICON */
.scroll-icon,
.scroll-icon:before {
    position: absolute;
    left: 50%;
}

body:not(#homepage) .scroll-icon {
    display: none;
}

.scroll-icon {
    width: 20px;
    height: 35px;
    margin-left: -20px;
    margin-top: -35px;
    box-shadow: inset 0 0 0 1px #fff;
    border-radius: 25px;
    bottom: 10px;

    &:before {
        content: '';
        width: 4px;
        height: 4px;
        background: #fff;
        margin-left: -2px;
        top: 8px;
        border-radius: 4px;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-name: scroll;
    }
}

@-moz-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}

@-webkit-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}

@-o-keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}

@keyframes scroll {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(10px);
    }
}